import {
    DocumentDuplicateIcon,
    PlusIcon,
    TrashIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { getServiceTasksByJob } from "../../services/serviceTasks/getServiceTasksByJob";
import DialogConfirmation from "../../components/Modals/DialogConfirmation";
import { cloneTaskWithChecklistItems } from "../../services/serviceTasks/cloneTaskWithChecklistItems";
import toast from "react-hot-toast";
import { getClientSubscriptionsByJobId } from "../../services/clientService/getClientSubscriptionsByJobId";
import { updateTaskV2 } from "../../services/serviceTasks/updateTaskV2";
import { deleteTaskV2 } from "../../services/serviceTasks/deleteTaskV2";
import { getNewLoggedInEmployeeFunction } from "../../helpers/getLoggedInEmployeeFunction";
import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";

const EditTasksFromJob = ({ title, jobId }) => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [showDialogForDuplicateJob, setShowDialogForDuplicateJob] =
        useState(false);
    const [jobIdToClone, setJobIdToClone] = useState(null);
    const [jobIdToDelete, setJobIdToDelete] = useState("");
    const [showDialogForMovingJob, setShowDialogForMovingJob] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [extraContent, setExtraContent] = useState(null);
    const [result, setResult] = useState(null);
    const [loggedInEmployee, setLoggedInEmployee] = useState(null);

    const getTasksFromAPI = async () => {
        const response = await getServiceTasksByJob(jobId);
        console.log(response);
        setTasks(response.data);
    };

    useEffect(() => {
        getTasksFromAPI();
        getNewLoggedInEmployeeFunction(setLoggedInEmployee);
    }, []);

    const isAdmin = () => {
        if (loggedInEmployee.role === 1) {
            return true;
        } else {
            toast.error("You don't have permission to do this");
            return false;
        }
    };

    /* const removeTask = async (task_id) => {
    const res = await deleteTaskV2(jobId, task_id);
    alert(res.data.message);
    if (res.data.success) {
      const tasks = await getServiceTasksByJob(jobId);
      setTasks(tasks.data);
    }
  }; */

    const validateSubscriptionsAffected = async () => {
        const response = await getClientSubscriptionsByJobId(jobId);
        if (response.data.success) {
            setExtraContent(response.data.clientServiceSubscriptions);
        } else {
            toast.error(response.data.message);
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async (result) => {
        if (!isAdmin()) {
            return;
        }
        // dropped outside the list
        validateSubscriptionsAffected();
        setResult(result);
        setShowDialogForMovingJob(true);

        /* if (!result.destination) {
      return;
    }
    const items = reorder(tasks, result.source.index, result.destination.index);
    setTasks(items);
    console.log(items);
    //update tasks in the database
    await Promise.all(
      items.map(async (item, index) => {
        await updateTaskV2({
          jobId: jobId,
          taskId: item.id,
          name: item.name,
          order: index,
        });
      })
    ); */
    };

    const onClickCloneJob = async (jobIdToClone) => {
        if (!isAdmin()) {
            return;
        }

        setJobIdToClone(jobIdToClone);
        setShowDialogForDuplicateJob(true);
        const response = await getClientSubscriptionsByJobId(jobId);
        if (response.data.success) {
            setExtraContent(response.data.clientServiceSubscriptions);
        } else {
            toast.error(response.data.message);
        }
    };

    const onDeleteJob = async () => {
        if (!isAdmin()) {
            return;
        }

        const response = await deleteTaskV2(jobId, jobIdToDelete);
        console.log(response);
        if (response.data.success) {
            toast.success(response.data.message);
            const tasks = await getServiceTasksByJob(jobId);
            setTasks(tasks.data);
        } else {
            toast.error(response.data.message);
        }
        setShowDialog(false);
    };

    const onConfirmJobToDuplicate = async () => {
        const response = await cloneTaskWithChecklistItems(jobIdToClone);
        console.log(response);
        if (response.data.success) {
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
        setShowDialogForDuplicateJob(false);
        getTasksFromAPI();
    };

    const onConfirmMoveJob = async () => {
        if (result.source.index === result.destination.index) {
            return;
        }
        const items = reorder(tasks, result.source.index, result.destination.index);
        setTasks(items);
        //update tasks in the database
        await Promise.all(
            items.map(async (item, index) => {
                await updateTaskV2({
                    jobId: jobId,
                    taskId: item.id,
                    name: item.name,
                    order: index,
                    updateType: true,
                });
            })
        );

        setShowDialogForMovingJob(false);
    };

    const onClickDeleteJob = async (jobId) => {
        if (!isAdmin()) {
            return;
        }

        validateSubscriptionsAffected();
        setShowDialog(true);
        setJobIdToDelete(jobId);
    };

    return (
        <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12 w-full">
            <DialogConfirmation
                showDialog={showDialogForDuplicateJob}
                setShowDialog={showDialogForDuplicateJob}
                onCancel={() => setShowDialogForDuplicateJob(false)}
                onConfirm={() => onConfirmJobToDuplicate()}
                title={"Are you sure you want to clone this job?"}
                description={"The job its checklist items will be copied too."}
            />
            <DialogConfirmation
                showDialog={showDialogForMovingJob}
                setShowDialog={setShowDialogForMovingJob}
                onCancel={() => setShowDialogForMovingJob(false)}
                onConfirm={() => onConfirmMoveJob()}
                title={"Are you sure you want to move this job?"}
                extraContent={extraContent}
            />
            <DialogConfirmation
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                onCancel={() => setShowDialog(false)}
                onConfirm={() => onDeleteJob()}
                title={"Are you sure you want to delete this job?"}
                extraContent={extraContent}
            />
            <header className="flex w-full justify-between items-center px-5 py-4 border-b border-slate-200">
                <h2 className="font-semibold text-slate-800">{title}</h2>
                <button
                    onClick={() =>
                        navigate(`/templates/job-templates/${jobId}/jobs/create`)
                    }
                    type="button"
                    className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <PlusIcon className="h-5 w-5" />
                </button>
            </header>

            <div className="space-y-5 px-5 py-4">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {tasks &&
                                    tasks.map((task, index) => {
                                        return (
                                            <Draggable
                                                key={task.id}
                                                draggableId={task.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div
                                                            key={index}
                                                            className="relative flex items-center my-4 border border-slate-300 rounded-sm p-2 bg-gray-200"
                                                        >
                                                            <div className="flex justify-between items-center w-full text-sm">
                                                                <ArrowsUpDownIcon className="h-4 w-4 mx-2" />
                                                                <label
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/templates/jobs/${jobId}/task/${task.id}`
                                                                        )
                                                                    }
                                                                    htmlFor="comments"
                                                                    className="w-full font-medium text-gray-700 hover:text-teal-500 cursor-pointer p-1"
                                                                >
                                                                    {task.name}
                                                                </label>
                                                                {/*  <button onClick={() => removeTask(task.id)}>
                                  <TrashIcon className="w-7 h-7 hover:text-red-500 p-1" />
                                </button> */}
                                                                <button
                                                                    onClick={() =>
                                                                        onClickCloneJob(
                                                                            task.id
                                                                        )
                                                                    }
                                                                    className="mx-2"
                                                                >
                                                                    {/*<TrashIcon className="w-7 h-7 hover:text-red-500 p-1" />*/}
                                                                    <DocumentDuplicateIcon className="h-5 w-5" />
                                                                </button>
                                                                <button
                                                                    onClick={() =>
                                                                        onClickDeleteJob(
                                                                            task.id
                                                                        )
                                                                    }
                                                                    className="mx-2"
                                                                >
                                                                    {/*<TrashIcon className="w-7 h-7 hover:text-red-500 p-1" />*/}
                                                                    <TrashIcon className="h-5 w-5 text-red-500" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default EditTasksFromJob;
