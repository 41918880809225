import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { getTimestamp } from "../../../../helpers/getTimestamp";
import { isValidLink } from "../../../../helpers/urlRegexValidator";
import { updateClientServiceSubscriptionTaskChecklistItem } from "../../../../services/clientServiceSubscriptionTaskChecklistItem/updateClientServiceSubscriptionTaskChecklistItem";
import ButtonAddTimestamp from "../../../../utils/ButtonAddTimestamp";
import LinkForm from "../../../clientServiceSubscription/links/LinkForm";
import InputTextModal from "../../modalGeneralComponents/InputTextModal";
import AssignedByListBox from "../../updateClientServiceSubscriptionTaskModal/AssignedByListBox";
import ButtonModal from "../../updateClientServiceSubscriptionTaskModal/ButtonModal";
import InputTextAreaModal from "../../updateClientServiceSubscriptionTaskModal/InputTextAreaModal";

const LINK_NAME = "name";
const LINK_URL = "url";

const EditClientServiceSubscriptionTaskChecklistItem = ({
    updateChecklistItem,
    open,
    setOpen,
    setUpdateChecklistItem,
    updateChecklistItems,
    loggedInEmployee,
    employees,
}) => {
    let completeButtonRef = useRef(null);
    const [newLink, setNewLink] = useState({ [LINK_NAME]: "", [LINK_URL]: "" });

    const onHandleClickAddTimestamp = (fieldName) => {
        setUpdateChecklistItem({
            ...updateChecklistItem,
            [fieldName]:
                (updateChecklistItem[fieldName] !== null
                    ? updateChecklistItem[fieldName]
                    : "") +
                getTimestamp(updateChecklistItem[fieldName], loggedInEmployee),
        });
    };

    const handleChangeLink = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setNewLink({ ...newLink, [name]: value });
    };

    const addLink = () => {
        if (newLink[LINK_NAME] !== "" && isValidLink(newLink[LINK_URL])) {
            setUpdateChecklistItem({
                ...updateChecklistItem,
                links: [...updateChecklistItem.links, newLink],
            });
            setNewLink({ [LINK_NAME]: "", [LINK_URL]: "" });
        } else {
            toast.error("Adding empty or invalid links is not allowed.");
        }
    };

    const deleteLink = (name) => {
        const newLinks = updateChecklistItem.links.filter(
            (link) => link[LINK_NAME] !== name
        );
        setUpdateChecklistItem({
            ...updateChecklistItem,
            links: newLinks,
        });
    };

    const onHandleChange = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        setUpdateChecklistItem({ ...updateChecklistItem, [fieldName]: fieldValue });
    };

    const onHandleChangeAssignedTo = (newAssignedTo) => {
        setUpdateChecklistItem({
            ...updateChecklistItem,
            assignedTo: newAssignedTo,
        });
    };

    /* const onChangeAssignedDate = (newAssignedDate) => {
        setUpdateChecklistItem({
            ...updateChecklistItem,
            assignedDate: newAssignedDate,
        });
    };

    const onChangeDueDate = (newAssignedDate) => {
        console.log(newAssignedDate);
        setUpdateChecklistItem({
            ...updateChecklistItem,
            dueDate: newAssignedDate,
        });
    }; */

    const onHandleSubmit = async (e) => {
        e.preventDefault();
        /* if (updateChecklistItem.assignedTo === null) {
            toast.error("Assigned to is required.");
            return;
        } */
        /* if (updateChecklistItem.assignedDate === "0001-01-01") {
            toast.error("Assigned date is required.");
            return;
        } */
        /* if (updateChecklistItem.dueDate === "0001-01-01T00:00:00") {
            toast.error("Due date is required.");
            return;
        } */
        const response = await updateClientServiceSubscriptionTaskChecklistItem(
            updateChecklistItem
        );
        if (response.data.success) {
            toast.success(response.data.message);
            updateChecklistItems(updateChecklistItem);
            setOpen(false);
        } else {
            toast.error(response.data.message);
        }
    };

    const onHandleCancel = () => {
        setOpen(false);
    };

    const unassignUserToChecklistItem = (e) => {
        e.preventDefault();
        if (loggedInEmployee.role == 1) {
            setUpdateChecklistItem({
                ...updateChecklistItem,
                assignedTo: null,
            });
        }
    };

    const onAssignDateChange = (e) => {
        if (e.target.value > updateChecklistItem.dueDate) {
            toast.error("Assign date cannot be grater than due date.");
            return;
        }
        setUpdateChecklistItem({
            ...updateChecklistItem,
            assignedDate: e.target.value,
        });
    };

    const onDueDateChange = (e) => {
        if (updateChecklistItem.assignedDate > e.target.value) {
            toast.error("Assign date cannot be grater than due date.");
            return;
        }
        setUpdateChecklistItem({ ...updateChecklistItem, dueDate: e.target.value });
    };

    return (
        <AnimatePresence>
            {open && (
                <Dialog
                    initialFocus={completeButtonRef}
                    static
                    as={motion.div}
                    open={open}
                    onClose={() => setOpen(false)}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 w-screen overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex min-h-full items-center justify-center p-4">
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                                <Dialog.Title className="text-center w-full text-lg leading-6 font-medium text-gray-900">
                                    Update Checklist Item
                                </Dialog.Title>

                                <form onSubmit={onHandleSubmit}>
                                    <InputTextModal
                                        required={true}
                                        label="Description"
                                        name="description"
                                        onChange={onHandleChange}
                                        value={updateChecklistItem.description}
                                    />

                                    <InputTextAreaModal
                                        label="Notes"
                                        name="notes"
                                        onChange={onHandleChange}
                                        value={updateChecklistItem.notes}
                                    />
                                    <ButtonAddTimestamp
                                        onHandleClick={() =>
                                            onHandleClickAddTimestamp("notes")
                                        }
                                    />

                                    <LinkForm
                                        newLink={newLink}
                                        addLink={addLink}
                                        handleChange={handleChangeLink}
                                        deleteLink={deleteLink}
                                        LINK_NAME={LINK_NAME}
                                        LINK_URL={LINK_URL}
                                        inputLinksJSON={updateChecklistItem.links}
                                        divWidth="w-full"
                                        title="Links"
                                    />

                                    <div className="flex flex-col mt-5 sm:mt-6">
                                        <h1 className="text-md font-normal">
                                            Assigned Date
                                        </h1>
                                        <input
                                            onChange={onAssignDateChange}
                                            type="date"
                                            id="start"
                                            name="trip-start"
                                            className="form-select pr-1 rounded-md border border-gray-200"
                                            value={
                                                updateChecklistItem.assignedDate &&
                                                updateChecklistItem.assignedDate.split(
                                                    "T"
                                                )[0]
                                            }
                                        ></input>
                                    </div>

                                    <div className="flex flex-col mt-5 sm:mt-6">
                                        <h1 className="text-md font-normal">
                                            Due Date
                                        </h1>
                                        <input
                                            onChange={onDueDateChange}
                                            type="date"
                                            id="dueDate"
                                            name="dueDate"
                                            className="form-select pr-1 rounded-md border border-gray-200"
                                            value={
                                                updateChecklistItem.dueDate &&
                                                updateChecklistItem.dueDate.split(
                                                    "T"
                                                )[0]
                                            }
                                        ></input>
                                    </div>

                                    <AssignedByListBox
                                        label="Assigned to"
                                        employees={employees}
                                        assignedBy={updateChecklistItem.assignedTo}
                                        onChange={onHandleChangeAssignedTo}
                                    />
                                    {loggedInEmployee.role == 1 && (
                                        <button
                                            onClick={unassignUserToChecklistItem}
                                            type="button"
                                            className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 text-xs mt-2"
                                        >
                                            Unassign user
                                        </button>
                                    )}

                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                        <ButtonModal
                                            name="Save"
                                            type="submit"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                                        />
                                        <ButtonModal
                                            name="Cancel"
                                            type="button"
                                            onClick={onHandleCancel}
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};

export default EditClientServiceSubscriptionTaskChecklistItem;
