import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import { getClientServiceSubscriptionTaskChecklistItemByTaskId } from "../../../services/clientServiceSubscriptionTaskChecklistItem/getClientServiceSubscriptionTaskChecklistItemByTaskId";
import { updateClientServiceSubscriptionTaskChecklistItem } from "../../../services/clientServiceSubscriptionTaskChecklistItem/updateClientServiceSubscriptionTaskChecklistItem";
import { updateClientServiceSubscriptionTaskChecklistItemCheck } from "../../../services/clientServiceSubscriptionTaskChecklistItem/updateClientServiceSubscriptionTaskChecklistItemCheck";
import { getEmployees } from "../../../services/getEmployees";
import ChecklistItemsList from "../../clientServiceSubscription/tasks/checklistItems/ChecklistItemsList";
import AddNewClientServiceSubscriptionTaskChecklistItem from "./addNewChecklistItem/AddNewClientServiceSubscriptionTaskChecklistItem";
import EditClientServiceSubscriptionTaskChecklistItem from "./updateChecklistItem/EditClientServiceSubscriptionTaskChecklistItem";
import { getBgColorFromDate } from "../../../helpers/getBgColorFromDate";
import Badge from "../../Badge";
import ConfirmationModal from "../../Modals/ConfirmationModal";

const ClientServiceSubscriptionTaskChecklistItemTableBody = ({
    task,
    loggedInEmployee,
    onUpdateAllChecklistItems,
    isServiceStatusInProgress,
    getNewJobs,
    updateClientService,
}) => {
    const [checklistItems, setChecklistItems] = useState([]);
    const [updateChecklistItem, setUpdateChecklistItem] = useState({});
    const [openEditChecklistItem, setOpenEditChecklistItem] = useState(false);
    const [openAddNewCheckListItem, setOpenAddNewChecklistItem] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [itemToChange, setItemToChange] = useState({});
    const [isChecked, setIsChecked] = useState(false);

    const onHandleClickAddNewChecklistItem = () => {
        setOpenAddNewChecklistItem(true);
    };

    const allItemsChecked = (checklistItems) => {
        console.log(checklistItems);
        let response = true;
        checklistItems.map((item) => {
            if (item.isChecked == false) {
                response = false;
                return;
            }
        });
        return response;
    };

    const onChangeItemInstructions = async (item, isChecked) => {
        console.log("aqui we", item);
        //Change status of checklist in a new variable
        const newCheckListItems = checklistItems.map((element) => {
            if (element.id === item.id) {
                return { ...element, isChecked: isChecked };
            } else {
                return element;
            }
        });
        //verify if all status are checked with the item passed.
        if (allItemsChecked(newCheckListItems)) {
            onUpdateAllChecklistItems(task, item);
        } else {
            const response =
                await updateClientServiceSubscriptionTaskChecklistItemCheck(
                    item.id,
                    isChecked
                );
            if (response.success) {
                setChecklistItems(newCheckListItems);
                getNewJobs();
                updateClientService();
                toast.success("Checklist item updated!");
            } else {
                toast.error("Error while updating checklist item.");
            }
        }
    };

    const onChangeItemCheckV2 = async (item, isChecked) => {
        console.log(task);
        if (!isServiceStatusInProgress && task.status !== 2) {
            toast.error("Must START service before completing any jobs.", {
                className: "font-bold",
            });
            return;
        }

        if (!isChecked && task.status === 2) {
            setShowDialog(true);
            setItemToChange(item);
            setIsChecked(isChecked);
            return;
        }

        onChangeItemInstructions(item, isChecked);
    };

    const onHandleClickEditItem = (item) => {
        setUpdateChecklistItem(item);
        setOpenEditChecklistItem(true);
    };

    const updateChecklistItems = (item) => {
        const newChecklistItems = checklistItems.map((checklistItem) => {
            if (checklistItem.id == item.id) {
                const newBgColor = getBgColorFromDate(item.dueDate);
                return { ...item, bgColor: newBgColor };
            }
            return checklistItem;
        });
        setChecklistItems(newChecklistItems);
    };

    const getChecklistItemsWithBgColor = (newChecklistItems) => {
        return newChecklistItems.map((item) => {
            const bgColor = getBgColorFromDate(item.dueDate);
            return { ...item, bgColor };
        });
    };

    const getNewChecklistItems = async () => {
        const newChecklistItems =
            await getClientServiceSubscriptionTaskChecklistItemByTaskId(task.id);
        const checklistItemsWithColors = getChecklistItemsWithBgColor(
            newChecklistItems.data.checklistItemsResponse
        );
        setChecklistItems(checklistItemsWithColors);
    };

    const getNewEmployees = async () => {
        const response = await getEmployees();
        setEmployees(response.data.employees);
    };

    useEffect(() => {
        getNewChecklistItems();
        getNewEmployees();
    }, [task]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = async (result) => {
        if (loggedInEmployee.role !== 1 || !isServiceStatusInProgress) {
            toast.error("You are not allowed to do this action.");
            return;
        }
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newChecklistItems = reorder(
            checklistItems,
            result.source.index,
            result.destination.index
        );
        setChecklistItems(newChecklistItems);
        await Promise.all(
            newChecklistItems.map(async (newChecklistItem, newOrder) => {
                const currentChecklistItem = {
                    ...newChecklistItem,
                    order: newOrder,
                };
                await updateClientServiceSubscriptionTaskChecklistItem(
                    currentChecklistItem
                );
            })
        );
    };

    return (
        <>
            <ConfirmationModal
                title="Job status will change!"
                description="Are you sure you want to uncheck this item?"
                showDialog={showDialog}
                onCancel={() => setShowDialog(false)}
                onConfirm={() => onChangeItemInstructions(itemToChange, isChecked)}
            />
            <AddNewClientServiceSubscriptionTaskChecklistItem
                open={openAddNewCheckListItem}
                setOpen={setOpenAddNewChecklistItem}
                taskId={task.id}
                getNewChecklistItems={getNewChecklistItems}
                loggedInEmployee={loggedInEmployee}
                employees={employees}
                updateClientService={updateClientService}
                getNewJobs={getNewJobs}
            />
            <EditClientServiceSubscriptionTaskChecklistItem
                updateChecklistItem={updateChecklistItem}
                setUpdateChecklistItem={setUpdateChecklistItem}
                open={openEditChecklistItem}
                setOpen={setOpenEditChecklistItem}
                updateChecklistItems={updateChecklistItems}
                loggedInEmployee={loggedInEmployee}
                employees={employees}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="text-sm divide-y divide-slate-200"
                        >
                            <tr className="pl-5 py-3 items-end">
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <button
                                        className="btn bg-teal-500 hover:bg-teal-600 text-white h-10 "
                                        onClick={onHandleClickAddNewChecklistItem}
                                    >
                                        <PlusIcon className="h-4 w-4" />{" "}
                                        <span> Add checklist item</span>
                                    </button>
                                </td>
                            </tr>
                            <tr className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="font-semibold text-left">
                                        Status
                                    </span>
                                    {/*   <div className="text-left">
              <button
                onClick={async () => UpdateAllChecklistItems(taskId, true)}
                className="btn bg-teal-500 hover:bg-teal-600 text-white h-10 my-2 mr-4"
              >
                Check All
              </button>
              <button
                onClick={async () => UpdateAllChecklistItems(taskId, false)}
                className="btn bg-red-500 hover:bg-red-600 text-white h-10 my-2 mr-4"
              >
                Uncheck All
              </button>
            </div> */}
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="font-semibold text-left">
                                        Description
                                    </span>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="font-semibold text-left">
                                        Notes
                                    </span>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="font-semibold text-left">
                                        Links
                                    </span>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="font-semibold text-left">
                                        Assigned to
                                    </span>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="font-semibold text-left">
                                        Assigned date
                                    </span>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="font-semibold text-left">
                                        Due date
                                    </span>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="font-semibold text-right">
                                        Edit
                                    </span>
                                </td>
                            </tr>

                            {checklistItems.map((item, index) => {
                                return (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <tr
                                                key={index}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <input
                                                        onChange={async (e) =>
                                                            onChangeItemCheckV2(
                                                                item,
                                                                e.target.checked
                                                            )
                                                        }
                                                        type="checkbox"
                                                        name="isChecked"
                                                        className="h-5 w-5 accent-teal-600  bg-grey-700  rounded cursor-pointer"
                                                        checked={item.isChecked}
                                                    ></input>
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="font-medium text-slate-800">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="px-2 first:pl-5 last:pr-5 py-3">
                                                    {item.notes !== null &&
                                                    item.notes !== "" ? (
                                                        <textarea
                                                            value={item.notes}
                                                            className="w-full h-full"
                                                            rows={3}
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <p className="font-semibold text-slate-800">
                                                            -
                                                        </p>
                                                    )}
                                                </td>

                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <ChecklistItemsList
                                                        links={item.links}
                                                    />
                                                </td>

                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="font-medium text-slate-800">
                                                            {item.assignedTo !== null
                                                                ? item.assignedTo
                                                                      .firstName +
                                                                  " " +
                                                                  item.assignedTo
                                                                      .lastName
                                                                : ""}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="font-medium text-slate-800">
                                                            {item.assignedDate !==
                                                            "0001-01-01T00:00:00"
                                                                ? item.assignedDate.split(
                                                                      "T"
                                                                  )[0]
                                                                : ""}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="font-medium text-slate-800">
                                                            {item.dueDate !==
                                                            "0001-01-01T00:00:00" ? (
                                                                <Badge
                                                                    label={
                                                                        item.dueDate.split(
                                                                            "T"
                                                                        )[0]
                                                                    }
                                                                    bgColor={
                                                                        item.bgColor
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                                                    {/* Menu button */}
                                                    <div className="w-full flex justify-end">
                                                        <button
                                                            onClick={() =>
                                                                onHandleClickEditItem(
                                                                    item
                                                                )
                                                            }
                                                            className="text-slate-400 hover:text-slate-500 rounded-full"
                                                        >
                                                            <span className="sr-only">
                                                                Menu
                                                            </span>
                                                            <PencilSquareIcon className="h-6 w-6" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};
export default ClientServiceSubscriptionTaskChecklistItemTableBody;
