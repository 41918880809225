import { Dialog, Menu, Transition } from "@headlessui/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getTimestamp } from "../../helpers/getTimestamp";
import { intervalTypeMap } from "../../helpers/intervalTypesMap";
import { addSubscription } from "../../services/addSubscription";
import { getEmployees } from "../../services/getEmployees";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import ButtonAddTimestamp from "../../utils/ButtonAddTimestamp";
import AssignedByListBox from "../clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/AssignedByListBox";
import InputTextAreaModal from "../clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/InputTextAreaModal";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function AddSubscriptionModal({
    open,
    setOpen,
    services,
    setServices,
    refreshSubs,
    client,
}) {
    const [employees, setEmployees] = useState([]);
    const [loggedInEmployee, setLoggedInEmployee] = useState({});
    const [isSelectedServiceRenewable, setIsSelectedServiceRenewable] =
        useState(false);
    const [form, setForm] = useState({
        service_id: services[0].id,
        serviceYear: new Date().getFullYear(),
        start: new Date().toISOString().split("T")[0],
        end: "",
        interval: "Annually",
        search: "",
        //assignedEmployees: [],
        notes: "",
        assignedEmployee: null,
        /* isEveryItemRenewable: false, */
    });

    const getNewLoggedInEmployee = useCallback(async () => {
        const currentEmployee = await getLoggedInEmployee();
        if (currentEmployee.success) {
            setLoggedInEmployee(currentEmployee.data.employeeResponse);
            console.log(currentEmployee);
        }
    }, []);

    const onHandleClickAddTimestamp = (fieldName) => {
        setForm({
            ...form,
            [fieldName]:
                (form[fieldName] !== null ? form[fieldName] : "") +
                getTimestamp(form.notes, loggedInEmployee),
        });
    };

    async function handleSubmit() {
        /* const startYear = new Date(form.start);
    const endYear = new Date(form.end);
    if (
      startYear.getFullYear() !== form.serviceYear ||
      endYear.getFullYear() !== form.serviceYear
    ) {
      toast.error("Start and End years must be the same as service's year.");
      return;
    } */

        const data = {
            client_id: client.id,
            interval: form.interval,
            serviceYear: form.serviceYear,
            start: form.start + "T00:00:00",
            end: form.end + "T00:00:00",
            service_id: form.service_id,
            serviceIsEfileStatusActive: form.serviceIsEfileStatusActive,
            //assignedEmployees: form.assignedEmployees,
            notes: form.notes,
            assignedByEmployee: loggedInEmployee,
            assignedEmployee: form.assignedEmployee,
            /* isEveryItemRenewable: form.isEveryItemRenewable, */
        };

        console.log(data);
        const res = await addSubscription(data);
        console.log(res);
        if (res.success) {
            setOpen(false);
            refreshSubs();
        } else {
            toast.error("Subscription could not be created");
        }
    }

    useEffect(() => {
        if (services === 0) return;
        setForm({
            ...form,
            service_id: services[0].id,
            serviceIsEfileStatusActive: services[0].isEfileStatusActive,
        });
        console.log(services);
        // eslint-disable-next-line
    }, [services]);

    useEffect(() => {
        if (form.search === "") {
            const arr = [...services];
            // eslint-disable-next-line
            arr.map((service) => {
                service.is_showing = true;
            });
            setServices(arr);
        }

        const arr = [...services];
        // eslint-disable-next-line
        arr.map((service) => {
            if (
                service.name.toLowerCase().includes(form.search.toLocaleLowerCase())
            ) {
                service.is_showing = true;
            } else {
                service.is_showing = false;
            }
        });
        setServices(arr);

        // eslint-disable-next-line
    }, [form.search]);

    useEffect(() => {
        const getNewEmployees = async () => {
            const response = await getEmployees();
            setEmployees(response.data.employees);
        };
        getNewEmployees();
        getNewLoggedInEmployee();
    }, []);

    /* const onHandleChangeAssignedEmployees = (newAssignedEmployees) => {
    setForm({
      ...form,
      assignedEmployees: newAssignedEmployees,
    });
    console.log(form);
  };
 */
    const onHandleChangeAssignEmployee = (newAssignedEmployee) => {
        setForm({
            ...form,
            assignedEmployee: newAssignedEmployee,
        });
        console.log(form);
    };

    const onChangeService = (service) => {
        console.log(service);
        setForm({
            ...form,
            service_id: service.id,
            serviceIsEfileStatusActive: service.isEfileStatusActive,
        });

        if (service.isEveryItemRenewable) {
            setIsSelectedServiceRenewable(true);
        } else {
            setIsSelectedServiceRenewable(false);
        }
    };

    const onAssignDateChange = (e) => {
        if (e.target.value > form.end) {
            toast.error("Assign date cannot be grater than due date.");
            return;
        }
        setForm({ ...form, start: e.target.value });
    };

    const onDueDateChange = (e) => {
        if (form.start > e.target.value) {
            toast.error("Assign date cannot be grater than due date.");
            return;
        }
        setForm({ ...form, end: e.target.value });
    };

    /*  const onChangeRenewJobsAutomatically = (e) => {
    console.log(e.target.checked);
    setForm({ ...form, isEveryItemRenewable: e.target.checked });
  }; */

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm w-full sm:p-6">
                                <div className="text-left">
                                    <div className="flex justify-between">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900"
                                        >
                                            Subscribe Service to:{" "}
                                            {client?.clientName}
                                        </Dialog.Title>
                                        <button onClick={() => setOpen(false)}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    </div>

                                    <div className="mt-5 sm:mt-6">
                                        <h1 className="text-md font-normal">
                                            Service
                                        </h1>
                                        <Menu
                                            as="div"
                                            className="relative inline-block text-left w-full"
                                        >
                                            <div>
                                                <Menu.Button
                                                    onClick={() => {
                                                        setForm({
                                                            ...form,
                                                            search: "",
                                                        });
                                                    }}
                                                    className="inline-flex w-full truncate justify-between rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                                                >
                                                    {form.service_id !== 1
                                                        ? services.filter(
                                                              (service) =>
                                                                  service.id ===
                                                                  form.service_id
                                                          )[0].name
                                                        : ""}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-4 h-4 mt-0.5"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="p-3 pb-1">
                                                        <input
                                                            onChange={(e) =>
                                                                setForm({
                                                                    ...form,
                                                                    search: e.target
                                                                        .value,
                                                                })
                                                            }
                                                            type="text"
                                                            value={form.search}
                                                            placeholder="Search.."
                                                            className="shadow-sm border border-slate-200 rounded-md p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                        />
                                                    </div>

                                                    <div className="py-1 max-h-72 overflow-auto w-full">
                                                        {
                                                            // eslint-disable-next-line
                                                            services.map(
                                                                (service, i) => {
                                                                    if (
                                                                        !service.is_showing
                                                                    )
                                                                        return;
                                                                    return (
                                                                        <Menu.Item
                                                                            key={i}
                                                                        >
                                                                            {({
                                                                                active,
                                                                            }) => (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        onChangeService(
                                                                                            service
                                                                                        )
                                                                                    }
                                                                                    className={classNames(
                                                                                        active
                                                                                            ? "bg-gray-100 text-gray-900"
                                                                                            : "text-gray-700",
                                                                                        "block px-4 py-2 text-sm cursor-pointer w-full text-left"
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        service.name
                                                                                    }
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    );
                                                                }
                                                            )
                                                        }
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                    {/* <RenewalJobsAutomaticallyCheckBox
                    name="Renew jobs automatically"
                    value={form.isEveryItemRenewable}
                    message="Check this box to renew jobs in this service automatically."
                    checked={form.isEveryItemRenewable}
                    onChange={(e) => onChangeRenewJobsAutomatically(e)}
                  /> */}
                                    <div className="mt-5 sm:mt-6">
                                        <h1 className="text-md font-normal">Year</h1>
                                        <input
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    serviceYear: e.target.value,
                                                })
                                            }
                                            className="relative inline-block text-left w-full"
                                            type="number"
                                            min="2000"
                                            max="2100"
                                            step="1"
                                            value={form.serviceYear}
                                        />
                                    </div>

                                    {isSelectedServiceRenewable && (
                                        <div className="mt-5 sm:mt-6">
                                            <h1 className="text-md font-normal">
                                                Interval
                                            </h1>

                                            <Menu
                                                as="div"
                                                className="relative inline-block text-left w-full"
                                            >
                                                <div>
                                                    <Menu.Button className="inline-flex w-full truncate justify-between w-full rounded-md border border-gray-200 bg-white pl-3 pr-2 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                                                        {form.interval}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            fill="currentColor"
                                                            className="w-4 h-4 mt-0.5"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </Menu.Button>
                                                </div>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="w-full absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1">
                                                            {intervalTypeMap.map(
                                                                (interval) => {
                                                                    return (
                                                                        <Menu.Item>
                                                                            {({
                                                                                active,
                                                                            }) => (
                                                                                <button
                                                                                    onClick={() => {
                                                                                        setForm(
                                                                                            {
                                                                                                ...form,
                                                                                                interval:
                                                                                                    interval.name,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    className={classNames(
                                                                                        active
                                                                                            ? "bg-gray-100 text-gray-900"
                                                                                            : "text-gray-700",
                                                                                        "block px-4 py-2 text-sm cursor-pointer w-full text-left"
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        interval.name
                                                                                    }
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    )}

                                    <div className="flex flex-col mt-5 sm:mt-6">
                                        <h1 className="text-md font-normal">
                                            Assigned Date
                                        </h1>
                                        <input
                                            onChange={onAssignDateChange}
                                            type="date"
                                            id="start"
                                            name="trip-start"
                                            className="form-select pr-1 rounded-md border border-gray-200"
                                            value={form.start}
                                        ></input>
                                    </div>

                                    <div className="flex flex-col mt-5 sm:mt-6">
                                        <h1 className="text-md font-normal">
                                            Due Date
                                        </h1>
                                        <input
                                            onChange={onDueDateChange}
                                            type="date"
                                            id="end"
                                            name="end"
                                            className="form-select pr-1 rounded-md border border-gray-200"
                                            value={form.end}
                                        ></input>
                                    </div>
                                    {/* <AssignedEmployeesListBox
                    title="Assign first job to *"
                    employees={employees}
                    assignedEmployees={form.assignedEmployees}
                    onChange={onHandleChangeAssignedEmployees}
                  /> */}
                                    <AssignedByListBox
                                        label="Assigned to"
                                        employees={employees}
                                        assignedBy={form.assignedEmployee}
                                        onChange={onHandleChangeAssignEmployee}
                                    />
                                    <InputTextAreaModal
                                        label="Permanent Notes"
                                        name="notes"
                                        onChange={(e) =>
                                            setForm({
                                                ...form,
                                                notes: e.target.value,
                                            })
                                        }
                                        value={form.notes}
                                    />
                                    <ButtonAddTimestamp
                                        onHandleClick={() =>
                                            onHandleClickAddTimestamp("notes")
                                        }
                                    />
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:text-sm"
                                        onClick={() => handleSubmit()}
                                    >
                                        Subscribe Service
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
